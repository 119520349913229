import React from 'react'
import { Link } from "react-router-dom";
import { Carousel, Image } from "react-bootstrap";
import "./carrocel.css"
//import img1 from '../assets/banner-BEM-VINDO-METODISTA.jpg'
import img1 from '../assets/banner-inicial.jpg'
import img2 from '../assets/banner-escola-idiomas.jpg'
import img3 from '../assets/banner-ouvidoria.jpg'
import img4 from '../assets/oportunidade professores.jpg'


function Carrocel() {
  return (

    <Carousel>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src={img1}
          alt="Banner abertura"
        />
      </Carousel.Item>

      {/* <Carousel.Item>
        <a href='https://drive.google.com/file/d/11P8NVp6SZcKuF1vzCGNHJNAaC6i8g4gL/view' target='_blank' >
          <img
            className="d-block w-100"
            src={img3}
            alt="" />
        </a>
      </Carousel.Item> */}

      <Carousel.Item>
        <a target="_blank" href="https://portal.mutue.ao/candidatura_docente/criar">
          <img
            className="d-block w-100"
            src={img4}
            alt="Candidatura à docentes"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <Link className="LinkStyle" to="/escoladeidiomas">
          <a target="_blank" href="https://forms.gle/emXUZmT5Z2HTXX9i8">
            <img
              className="d-block w-100"
              src={img2}
              alt="Escola de idiomas"
            />
          </a>
        </Link>
      </Carousel.Item>

      <Carousel.Item>
        <Link className="LinkStyle" to="/ouvidoria">
          <img
            className="d-block w-100"
            src={img3}
            alt="Ouvidoria"
          />
        </Link>
      </Carousel.Item>






      {/* <Carousel.Item>
          <Link className="LinkStyle" to="/poslaboral">
            <img
              className="d-block w-100"
              src={img2}
              alt="Five slide"
            />
          </Link>
        </Carousel.Item> */}

      {/* <Carousel.Item>
          <Link className="LinkStyle" to="/curso22">
            <img
              className="d-block w-100"
              src={img1}
              alt="Five slide"
            />
          </Link>
        </Carousel.Item> */}


    </Carousel>
  );
}

export default Carrocel;